pre { line-height: 125%; }
td.linenos .normal { color: inherit; background-color: transparent; padding-left: 5px; padding-right: 5px; }
span.linenos { color: inherit; background-color: transparent; padding-left: 5px; padding-right: 5px; }
td.linenos .special { color: #000000; background-color: #ffffc0; padding-left: 5px; padding-right: 5px; }
span.linenos.special { color: #000000; background-color: #ffffc0; padding-left: 5px; padding-right: 5px; }
.code-highlight .hll { background-color: #ffffcc }
.code-highlight { background: #f8f8f8; }
.code-highlight .c { color: #3D7B7B; font-style: italic } /* Comment */
.code-highlight .err { border: 1px solid #FF0000 } /* Error */
.code-highlight .k { color: #008000; font-weight: bold } /* Keyword */
.code-highlight .o { color: #666666 } /* Operator */
.code-highlight .ch { color: #3D7B7B; font-style: italic } /* Comment.Hashbang */
.code-highlight .cm { color: #3D7B7B; font-style: italic } /* Comment.Multiline */
.code-highlight .cp { color: #9C6500 } /* Comment.Preproc */
.code-highlight .cpf { color: #3D7B7B; font-style: italic } /* Comment.PreprocFile */
.code-highlight .c1 { color: #3D7B7B; font-style: italic } /* Comment.Single */
.code-highlight .cs { color: #3D7B7B; font-style: italic } /* Comment.Special */
.code-highlight .gd { color: #A00000 } /* Generic.Deleted */
.code-highlight .ge { font-style: italic } /* Generic.Emph */
.code-highlight .ges { font-weight: bold; font-style: italic } /* Generic.EmphStrong */
.code-highlight .gr { color: #E40000 } /* Generic.Error */
.code-highlight .gh { color: #000080; font-weight: bold } /* Generic.Heading */
.code-highlight .gi { color: #008400 } /* Generic.Inserted */
.code-highlight .go { color: #717171 } /* Generic.Output */
.code-highlight .gp { color: #000080; font-weight: bold } /* Generic.Prompt */
.code-highlight .gs { font-weight: bold } /* Generic.Strong */
.code-highlight .gu { color: #800080; font-weight: bold } /* Generic.Subheading */
.code-highlight .gt { color: #0044DD } /* Generic.Traceback */
.code-highlight .kc { color: #008000; font-weight: bold } /* Keyword.Constant */
.code-highlight .kd { color: #008000; font-weight: bold } /* Keyword.Declaration */
.code-highlight .kn { color: #008000; font-weight: bold } /* Keyword.Namespace */
.code-highlight .kp { color: #008000 } /* Keyword.Pseudo */
.code-highlight .kr { color: #008000; font-weight: bold } /* Keyword.Reserved */
.code-highlight .kt { color: #B00040 } /* Keyword.Type */
.code-highlight .m { color: #666666 } /* Literal.Number */
.code-highlight .s { color: #BA2121 } /* Literal.String */
.code-highlight .na { color: #687822 } /* Name.Attribute */
.code-highlight .nb { color: #008000 } /* Name.Builtin */
.code-highlight .nc { color: #0000FF; font-weight: bold } /* Name.Class */
.code-highlight .no { color: #880000 } /* Name.Constant */
.code-highlight .nd { color: #AA22FF } /* Name.Decorator */
.code-highlight .ni { color: #717171; font-weight: bold } /* Name.Entity */
.code-highlight .ne { color: #CB3F38; font-weight: bold } /* Name.Exception */
.code-highlight .nf { color: #0000FF } /* Name.Function */
.code-highlight .nl { color: #767600 } /* Name.Label */
.code-highlight .nn { color: #0000FF; font-weight: bold } /* Name.Namespace */
.code-highlight .nt { color: #008000; font-weight: bold } /* Name.Tag */
.code-highlight .nv { color: #19177C } /* Name.Variable */
.code-highlight .ow { color: #AA22FF; font-weight: bold } /* Operator.Word */
.code-highlight .w { color: #bbbbbb } /* Text.Whitespace */
.code-highlight .mb { color: #666666 } /* Literal.Number.Bin */
.code-highlight .mf { color: #666666 } /* Literal.Number.Float */
.code-highlight .mh { color: #666666 } /* Literal.Number.Hex */
.code-highlight .mi { color: #666666 } /* Literal.Number.Integer */
.code-highlight .mo { color: #666666 } /* Literal.Number.Oct */
.code-highlight .sa { color: #BA2121 } /* Literal.String.Affix */
.code-highlight .sb { color: #BA2121 } /* Literal.String.Backtick */
.code-highlight .sc { color: #BA2121 } /* Literal.String.Char */
.code-highlight .dl { color: #BA2121 } /* Literal.String.Delimiter */
.code-highlight .sd { color: #BA2121; font-style: italic } /* Literal.String.Doc */
.code-highlight .s2 { color: #BA2121 } /* Literal.String.Double */
.code-highlight .se { color: #AA5D1F; font-weight: bold } /* Literal.String.Escape */
.code-highlight .sh { color: #BA2121 } /* Literal.String.Heredoc */
.code-highlight .si { color: #A45A77; font-weight: bold } /* Literal.String.Interpol */
.code-highlight .sx { color: #008000 } /* Literal.String.Other */
.code-highlight .sr { color: #A45A77 } /* Literal.String.Regex */
.code-highlight .s1 { color: #BA2121 } /* Literal.String.Single */
.code-highlight .ss { color: #19177C } /* Literal.String.Symbol */
.code-highlight .bp { color: #008000 } /* Name.Builtin.Pseudo */
.code-highlight .fm { color: #0000FF } /* Name.Function.Magic */
.code-highlight .vc { color: #19177C } /* Name.Variable.Class */
.code-highlight .vg { color: #19177C } /* Name.Variable.Global */
.code-highlight .vi { color: #19177C } /* Name.Variable.Instance */
.code-highlight .vm { color: #19177C } /* Name.Variable.Magic */
.code-highlight .il { color: #666666 } /* Literal.Number.Integer.Long */
